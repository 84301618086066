import type { LineItem, Order } from '@commercelayer/sdk';
import type { Entry, EntryCollection } from 'contentful';

import type {
  DigitalProduct,
  Product,
  ProductBenefitParsed,
  UpsellProduct,
} from 'productSelection/types/products';
import type { Channel } from 'shared/constants/Channel';
import { PRODUCT_ALIASES } from 'shared/constants/ProductConstants';
import { getCountryCodeFromISOLocale } from 'shared/i18n/helpers';
import { translateProductToStore } from 'shared/infra/commerceLayer/utils';
import { getCatalogEntriesByCountry } from 'shared/infra/contentful';
import type {
  ICatalogFields,
  IDigitalProduct,
  IProduct,
  IProductBenefitFields,
  IStoreProduct,
  LOCALE_CODE,
} from 'shared/infra/contentful/contentful';
import { getOrderSubscriptionFromEcomPlatform } from 'shared/infra/storefront/services';
import {
  convertImageToPng,
  forceImgSrcToHttps,
} from 'shared/services/imageUrl';
import type { OrderLineItem } from 'shared/store/order/types';
import { createSKUCode, type SKUCode } from 'shared/types/ids';
import type {
  CoreProduct,
  LineItemType,
  ProductType,
  ProductTypeIdentifier,
} from 'shared/types/product';
import { SUMUP_ONE_SKU } from 'src/cart/services/CartOverviewService';
import type { Maybe } from 'types/util';
import { formatCurrencyWithLocale } from 'utils/currency';

import { getUpsellProduct } from './BusinessAccount';
import logger from './logger';
import { retrieveGlobalLocale } from './OrderInformationService.globals';
import { productSelector } from './ProductSelector';

export type OrderSubscription = {
  currency_code: string;
  total_amount_cents: number;
  total_amount_float: number;
  tax_amount_cents: number;
  tax_amount_float: number;
  total_amount_with_taxes_and_discounts_cents: number;
  total_amount_with_taxes_and_discounts_float: number;
  discount_cents: number;
  discount_float: number;
  tax_rate: number;
  trial_days: number;
  subscriptions: Subscription[];
};

export type Subscription = {
  id: string;
  sku_code: string;
  discount_percentage: number;
  currency_code: string;
  total_amount_cents: number;
  total_amount_float: number;
  tax_amount_cents: number;
  tax_amount_float: number;
  total_amount_with_taxes_and_discounts_cents: number;
  total_amount_with_taxes_and_discounts_float: number;
  discount_cents: number;
  discount_float: number;
  tax_rate: number;
  frequency: 'monthly' | 'yearly';
  plan_id: string;
  quantity: number;
  trial_days: number;
};

export type PaymentInstrument =
  | CreditCardPaymentInstrument
  | SepaPaymentInstrument;

export type CreditCardPaymentInstrument = {
  id: string;
  type: 'credit_card_payment_instruments';
  attributes: {
    scheme:
      | 'AMEX'
      | 'CUP'
      | 'DINERS'
      | 'DISCOVER'
      | 'ELO'
      | 'ELV'
      | 'HIPERCARD'
      | 'JCB'
      | 'MAESTRO'
      | 'MASTERCARD'
      | 'UNKNOWN'
      | 'VISA'
      | 'VISA_ELECTRON'
      | 'VISA_VPAY';
    last4: string;
  };
};

export type SepaPaymentInstrument = {
  id: string;
  type: 'sepa_payment_instruments';
  attributes: {
    masked_iban: string;
  };
};

interface ProductTrackingInfo {
  code: string;
  trackingId: string;
  price: string;
  quantity: number;
}

const DUE_NOW_PRODUCT_TYPES: ProductTypeIdentifier[] = ['hardware', 'service'];

const CONDITIONS_PRODUCT_TYPES: ProductTypeIdentifier[] = [
  'contract_duration',
  'fee_campaign',
  'subscription_discount',
];

const IS_CF_MASTER = process.env.CONTENTFUL_ENVIRONMENT === 'master';

const parseContentfulProductBenefit = (
  productBenefit: IProductBenefitFields,
): ProductBenefitParsed => ({
  name: productBenefit?.name || '',
  title: productBenefit?.title || '',
  description: productBenefit?.description || '',
  icon: {
    imageSrc: forceImgSrcToHttps(productBenefit?.icon?.fields?.file?.url || ''),
    imageAltText: productBenefit?.icon?.fields?.description || '',
  },
});

export const parseContentfulDigitalProducts = (
  digitalProducts: IDigitalProduct[],
): DigitalProduct[] =>
  digitalProducts.map(
    (digitalProduct): DigitalProduct => ({
      ...digitalProduct.fields,
      id: digitalProduct.sys.id,
      slug: digitalProduct.fields.trackingId,
      productContent: {
        imageSrc: convertImageToPng(
          digitalProduct.fields.images[0]?.fields.file.url || '',
        ),
        imageAltText: digitalProduct.fields.images[0]?.fields.description || '',
      },
      images:
        digitalProduct.fields.images?.map((image) => ({
          id: image.sys.id,
          imageSrc: convertImageToPng(image.fields.file.url),
          imageAltText: image.fields.description || '',
        })) || [],
      shouldShowHighlight: digitalProduct.fields.showProductHighlight,
      highlightTitle: digitalProduct.fields.productHighlightTitle || '',
      highlightDescription:
        digitalProduct.fields.productHighlightDescription || '',
      selectProductLabel: digitalProduct.fields.selectProductLabel || '',
      selectedProductLabel: digitalProduct.fields.selectedProductLabel || '',

      productDescription: digitalProduct.fields.productDescription || '',
      faqs: (digitalProduct.fields.faqs || []).map((item) => item.fields),

      productBenefits: (digitalProduct.fields.productBenefits || []).map((pb) =>
        parseContentfulProductBenefit(pb.fields),
      ),
      acceptedCardSchemaLabel:
        digitalProduct.fields.acceptedCardSchemaLabel || '',
      cardSchemasAccepted: digitalProduct.fields.acceptedCardsSchemas || [],

      otherPaymentTypesLabel:
        digitalProduct.fields.otherPaymentTypesLabel || '',
      otherAcceptedPaymentTypes:
        digitalProduct.fields.otherAcceptedPaymentTypes || [],

      ...(digitalProduct.fields.howItWorks && {
        howItWorks: digitalProduct.fields.howItWorks,
      }),
      howItWorksHeadline: digitalProduct.fields.howItWorksHeadline || '',
      isFree: digitalProduct.fields.isFree,
      hideProductSummary: digitalProduct.fields.hideProductSummary,
    }),
  );

const isStoreProduct = (product: Entry<unknown>): product is IStoreProduct =>
  product.sys.contentType.sys.id === 'storeProduct';

export const getProductType = (
  product: IStoreProduct | IProduct,
): ProductType => {
  if (isStoreProduct(product)) {
    return product.fields.type.fields as ProductType;
  }

  return product.fields.kind.fields as ProductType;
};

export const getLineItemType = (
  product: IStoreProduct | IProduct,
): LineItemType => (product.fields.bundleCode ? 'bundle' : 'sku');

export const isSubscriptionProduct = (
  product: Product | CoreProduct,
): boolean => product.productType?.identifier === 'license';

export const isCondition = (product: Product): boolean =>
  CONDITIONS_PRODUCT_TYPES.includes(product.productType.identifier);

export const isFeeCondition = (product: Product): boolean =>
  product.productType.identifier === 'fee_campaign';

export const isDueNowProduct = (product: Product): boolean =>
  DUE_NOW_PRODUCT_TYPES.includes(product.productType.identifier);

export const getCode = (product: IStoreProduct | IProduct): Maybe<SKUCode> => {
  if (!product.fields) {
    throw new Error(`Product ID ${product.sys.id} has no fields`);
  }

  const skuCode = isStoreProduct(product)
    ? product.fields.sku_code
    : product.fields.skuCode;

  const { bundleCode } = product.fields;

  if (skuCode && bundleCode) {
    logger
      .withContext({
        tags: {
          skuCode,
          bundleCode,
          productId: product.sys.id,
        },
      })
      .error(new Error('Product has both sku_code and bundleCode'));

    return null;
  }

  if (skuCode) {
    return createSKUCode(skuCode);
  }

  if (bundleCode) {
    return createSKUCode(bundleCode);
  }

  logger
    .withContext({ tags: { productId: product.sys.id } })
    .error(new Error('Product has neither sku_code nor bundleCode'));

  return null;
};

export const parseContentfulUpsellProducts = (
  channel: Channel,
  countryCode: string,
): UpsellProduct[] => {
  if (channel !== 'signup' || countryCode !== 'GB') {
    return [];
  }

  return [getUpsellProduct()];
};

const hasUpsellProduct = (trackingId: string): boolean => {
  const trackingIds = IS_CF_MASTER
    ? [
        'card_reader.solo_bundle_cradle',
        'card_reader.solo_cradle_bt_pos_lite_bundle_uk',
        'card_reader.air_bundle',
        'card_reader.air',
        'card_reader.solo_bundle_printer',
      ]
    : [
        'card_reader.air_bundle',
        'card_reader.solo_bundle_cradle',
        'accessories.solo_printer',
        'card_reader.bp55_bundle_printer',
        'card_reader.air',
      ];

  return trackingIds.includes(trackingId);
};

const hasProductSelector = (trackingId: string): boolean => {
  // hardcoded relationships of "product -> your own bank account"
  const trackingIds = IS_CF_MASTER
    ? [
        'card_reader.solo_bundle_cradle',
        'card_reader.solo_cradle_bt_pos_lite_bundle_uk',
        'card_reader.air_bundle',
        'card_reader.air',
        'card_reader.solo_bundle_printer',
      ]
    : [
        'card_reader.air_bundle',
        'card_reader.solo_bundle_cradle',
        'accessories.solo_printer',
        'card_reader.bp55_bundle_printer',
        'card_reader.air',
      ];

  return trackingIds.includes(trackingId);
};

const parseProduct = (product: IProduct): Maybe<CoreProduct> => {
  const code = getCode(product);

  if (!code) {
    return null;
  }

  const result: CoreProduct = {
    code: createSKUCode(code),
    lineItemType: getLineItemType(product),
    trackingId: product.fields.trackingId,
    title: product.fields.title,
    description: product.fields.description ?? null,
    productType: getProductType(product),
    assets: product.fields.assets.map((asset) => ({
      id: asset.sys.id,
      imageSrc: convertImageToPng(asset.fields.file.url),
      imageAltText: asset.fields.description ?? '',
    })),
  };

  return result;
};

export const parseContentfulProducts = (
  productsFromMarket: IProduct[],
): CoreProduct[] =>
  productsFromMarket.flatMap((product) => {
    const parsed = parseProduct(product);

    return parsed ? [parsed] : [];
  });

const parseStoreProduct = (product: IStoreProduct): Maybe<Product> => {
  const code = getCode(product);

  if (!code) {
    return null;
  }

  return {
    id: product.sys.id,
    code,
    productType: getProductType(product),
    lineItemType: getLineItemType(product),
    name: product.fields.title,
    trackingId: product.fields.trackingId,
    slug: product.fields.trackingId,
    productContent: {
      imageSrc: convertImageToPng(product.fields.images[0]?.fields.file.url),
      imageAltText: product.fields.images[0]?.fields.description || '',
    },
    images: product.fields.images.map((image) => ({
      id: image.sys.id,
      imageSrc: convertImageToPng(image.fields.file.url),
      imageAltText: image.fields.description || '',
    })),
    bulletPoints: product.fields.bulletPoints,
    shouldShowHighlight: product.fields.shouldShowHighlight,
    highlightTitle: product.fields.highlightTitle || '',
    highlightDescription: product.fields.highlightDescription || '',
    highlightIcon: product.fields.highlightIcon,
    productBenefits: (product.fields.productBenefits || []).map((pb) =>
      parseContentfulProductBenefit(pb.fields),
    ),
    acceptedCardSchemaLabel: product.fields.acceptedCardSchemaLabel || '',
    cardSchemasAccepted: (product.fields.acceptedCardsSchemas ||
      []) as string[],
    otherPaymentTypesLabel: product.fields.otherPaymentTypesLabel || '',
    otherAcceptedPaymentTypes: (product.fields.otherAcceptedPaymentTypes ||
      []) as string[],
    ...(product.fields.deliveryTime && {
      deliveryTime: product.fields.deliveryTime,
    }),
    ...(product.fields.summaryDescription && {
      summaryDescription: product.fields.summaryDescription,
    }),
    moneyBackGuarantee: product.fields.moneyBackGuarantee || '',
    productDescription: product.fields.productDescription || '',
    shippingFee: product.fields.shippingFee || '',
    faqs: (product.fields.faqs || []).map((item) => item.fields),
    numberOfInstallments: product.fields.installments || 1,
    installmentsFeeInfo: product.fields.installmentsFeeInfo || '',
    fullPriceInfo: product.fields.fullPriceInfo || '',
    selectProductLabel: product.fields.selectProductLabel || '',
    selectedProductLabel: product.fields.selectedProductLabel || '',
    shortBenefits: product.fields.shortBenefits || '',
    ...(hasUpsellProduct(product.fields.trackingId) && {
      businessAccount: getUpsellProduct(),
    }),
    ...(hasProductSelector(product.fields.trackingId) && {
      productSelector: productSelector(),
    }),
    transactionFee: product.fields.transactionFee || '',
    ...('plan' in product.fields && {
      plan: product.fields.plan,
    }),
  };
};

export const parseContentfulStoreProducts = (
  contentfulProducts: IStoreProduct[],
): Product[] =>
  contentfulProducts.flatMap((product) => {
    const parsed = parseStoreProduct(product);

    return parsed ? [parsed] : [];
  });

export const getProductByTrackingId = <T extends Product | DigitalProduct>(
  products: T[],
  trackingId: string,
): T | undefined =>
  products?.find(
    (product) => 'trackingId' in product && product.trackingId === trackingId,
  );

export const reorderCatalogByQuery = <T extends Product | DigitalProduct>(
  products: T[],
  productsOnQuery: string[] | undefined,
): T[] => {
  if (productsOnQuery) {
    return productsOnQuery.reduce<T[]>(
      (acc, current: keyof typeof PRODUCT_ALIASES) => {
        /**
       * Check for available aliases for existing products. This is only
         necessary while the old shop is still supported. The goal is
         to deprecate this logic and remove aliases as soon as the
         storefront becomes the default platform.
       */
        const productTrackingId = PRODUCT_ALIASES[current] || current;

        const product = getProductByTrackingId(products, productTrackingId);

        return product ? [...acc, product] : acc;
      },
      [],
    );
  }
  return products;
};

export const filterProductCatalog = (
  products: Product[],
  digitalProducts: DigitalProduct[],
  productsOnQuery: string[] | undefined,
): {
  products: Product[];
  digitalProducts: DigitalProduct[];
} => {
  const filteredProducts = reorderCatalogByQuery(products, productsOnQuery);
  const filteredDigitalProducts = reorderCatalogByQuery(
    digitalProducts,
    productsOnQuery,
  );

  if (filteredProducts.length > 0 || filteredDigitalProducts.length > 0) {
    return {
      products: filteredProducts,
      digitalProducts: filteredDigitalProducts,
    };
  }

  return {
    products,
    digitalProducts,
  };
};

export const joinCatalogProductsForCountry = (
  countryCatalogEntries: EntryCollection<ICatalogFields>,
): IStoreProduct[] => {
  /**
   * We need a list of all products available on a country.
   * uniqueProductsById is an objects of Contentful product information by id,
   * without duplicates.
   */
  const uniqueProductsById = countryCatalogEntries.items.reduce(
    (productsMap, current) => {
      const catalogProductsMap = current.fields.products.reduce(
        (productsById, currentProduct) => ({
          ...productsById,
          [currentProduct.sys.id]: currentProduct,
        }),
        {},
      );

      return {
        ...productsMap,
        ...catalogProductsMap,
      };
    },
    {},
  );

  return Object.values(uniqueProductsById);
};

export const findProductByCode = <T extends { code: string }>(
  products: T[],
  code: Maybe<string>,
): Maybe<T> => (code && products.find((p) => p.code === code)) || null;

export const findProductByCodeAndType = <
  T extends { code: string; lineItemType: LineItemType },
>(
  products: T[],
  code: string,
  type: LineItemType,
): Maybe<T> =>
  findProductByCode(
    products.filter((p) => p.lineItemType === type),
    code,
  );

export const combineProductsInfo = (
  products: Product[],
  upsellProductsContentful: UpsellProduct[],
  marketProducts: CoreProduct[],
  lineItems: OrderLineItem[],
  orderSubscription: Maybe<OrderSubscription>,
  locale = retrieveGlobalLocale(),
): { products: Product[]; unavailableLineItems: OrderLineItem[] } => {
  const combinedProducts: Product[] = [];
  const unavailableLineItems: OrderLineItem[] = [];

  lineItems.forEach((lineItem) => {
    const matchedUpsellProduct = findProductByCode(
      upsellProductsContentful,
      lineItem.code,
    );
    if (matchedUpsellProduct) {
      return;
    }

    const subscription = orderSubscription?.subscriptions?.find(
      (sub) => sub.sku_code === lineItem.code,
    );

    // First check if the product exists in the market (availability)
    const productFromMarket = findProductByCodeAndType(
      marketProducts,
      lineItem.code,
      lineItem.lineItemType,
    );

    // If product is not in the market, it's not available for sale
    if (!productFromMarket) {
      unavailableLineItems.push(lineItem);
      return;
    }

    // Then check if we have additional StoreProduct information from the catalog
    const productFromCatalog = findProductByCodeAndType(
      products,
      lineItem.code,
      lineItem.lineItemType,
    );

    if (!productFromCatalog) {
      // Product is available in the market but not in the catalog
      // Use market product data
      const combinedProduct: Product = {
        id: lineItem.id,
        trackingId: productFromMarket.trackingId,
        slug: productFromMarket.trackingId,
        name: productFromMarket.title,
        lineItemType: productFromMarket.lineItemType,
        code: productFromMarket.code,
        productContent: productFromMarket.assets[0],
        productType: productFromMarket.productType,
        ...(productFromMarket.description && {
          shortBenefits: productFromMarket.description,
        }),
        discountCents: lineItem.discountCents,
        discountRate: lineItem.discountRate,
        quantity: lineItem.quantity,
        formattedTotalAmount: formatCurrencyWithLocale(
          lineItem.amountFloat,
          locale,
        ),
        formattedUnitAmount: formatCurrencyWithLocale(
          lineItem.unitAmountFloat,
          locale,
        ),
        amountFloat: lineItem.amountFloat,
        totalAmountFloat: lineItem.totalAmountFloat,
        discountFloat: lineItem.discountFloat,
        ...(isSubscriptionProduct(productFromMarket) &&
          subscription && { subscription }),
        productDescription: productFromMarket.description,

        // for out of catalog products we don't have this information
        numberOfInstallments: 1, // relevant in checkout funnel
        businessAccount: undefined, // not relevant in checkout funnel
        plan: undefined, // used for SumUp One I think (TODO)
      };
      combinedProducts.push(combinedProduct);
      return;
    }

    // Product is in both market and catalog
    // Combine data, using product from catalog (StoreProduct) for most fields
    // This is a legacy code path that should be removed in LG-3292 once we've figured out how to detach the StoreProduct from the checkout funnel
    const combinedProduct: Product = {
      id: lineItem.id,
      trackingId: productFromCatalog.trackingId,
      name: productFromCatalog.name,
      lineItemType: productFromCatalog.lineItemType,
      productType: productFromCatalog.productType,
      productContent: productFromCatalog.productContent,
      productDescription: productFromCatalog.productDescription,
      code: productFromCatalog.code,
      discountCents: lineItem.discountCents,
      discountRate: lineItem.discountRate,
      quantity: lineItem.quantity,
      slug: productFromCatalog.slug,
      formattedTotalAmount: formatCurrencyWithLocale(
        lineItem.amountFloat,
        locale,
      ),
      formattedUnitAmount: formatCurrencyWithLocale(
        lineItem.unitAmountFloat,
        locale,
      ),
      shortBenefits: productFromCatalog.shortBenefits || '',
      amountFloat: lineItem.amountFloat,
      numberOfInstallments: productFromCatalog.numberOfInstallments,
      businessAccount: productFromCatalog.businessAccount,
      totalAmountFloat: lineItem.totalAmountFloat,
      discountFloat: lineItem.discountFloat,
      ...(isSubscriptionProduct(productFromCatalog) &&
        subscription && { subscription }),
      plan: productFromCatalog.plan,
    };

    combinedProducts.push(combinedProduct);
  });

  return {
    products: combinedProducts,
    unavailableLineItems,
  };
};

export const getLineItemsFromOrder = async (
  order: Order,
  locale: LOCALE_CODE,
): Promise<{
  products: Product[];
  largestInstallmentInCart: number;
  hasBusinessAccount: boolean;
}> => {
  // fetch and join all catalogs (and Store Products) to calculate the largest installment
  // TODO: to be removed in LG-3292
  const allCountryCatalogEntries = await getCatalogEntriesByCountry(
    getCountryCodeFromISOLocale(locale) ?? '',
    locale,
  );

  const productEntriesForCountry = joinCatalogProductsForCountry(
    allCountryCatalogEntries,
  );

  const productsContentful = parseContentfulStoreProducts(
    productEntriesForCountry,
  );

  const countryAvailableProducts = parseContentfulProducts(
    allCountryCatalogEntries.items[0].fields.market.fields.products ?? [],
  );

  const productsInCart = (order.line_items || [])
    .filter((li) => li.item_type === 'skus' || li.item_type === 'bundles')
    .map((li) => translateProductToStore(li));

  const codesInCart = productsInCart.map((p) => p.code);

  const productInstallments = productsContentful
    .filter((p) => codesInCart.includes(p.code))
    .map((p) => p.numberOfInstallments ?? 1);

  const largestInstallmentInCart = Math.max(...productInstallments);

  let orderSubscription: Maybe<OrderSubscription> = null;

  if (hasOrderSubscriptionInCart(productsInCart, countryAvailableProducts)) {
    orderSubscription = await getOrderSubscriptionFromEcomPlatform(order.id);
    if (!orderSubscription) {
      throw new Error(
        'missing order subscriptions for order with subscription line item',
      );
    }
  }

  const businessAccount = getUpsellProduct();
  const hasBusinessAccount = codesInCart.some(
    (code) => code === businessAccount.code,
  );

  const { products } = combineProductsInfo(
    productsContentful,
    [businessAccount],
    countryAvailableProducts,
    productsInCart,
    orderSubscription,
    locale,
  );

  return { products, largestInstallmentInCart, hasBusinessAccount };
};

export function getConditionProducts(products: Product[]): Product[] {
  return products.filter(isCondition);
}

export function getDueNowProducts(products: Product[]): Product[] {
  return products.filter(isDueNowProduct);
}

const hasProductOfProductTypeInCart = (
  productTypeIdentifier: ProductTypeIdentifier,
  productsInCart: OrderLineItem[],
  countryAvailableProducts: CoreProduct[],
): boolean => {
  const codesInCart = new Set(productsInCart.map((product) => product.code));

  return [...codesInCart].some((code) =>
    countryAvailableProducts.some(
      (product) =>
        product.productType.identifier === productTypeIdentifier &&
        product.code === code,
    ),
  );
};

export function hasShippableProductsInCart(
  productsInCart: OrderLineItem[],
  countryAvailableProducts: CoreProduct[],
): boolean {
  return hasProductOfProductTypeInCart(
    'hardware',
    productsInCart,
    countryAvailableProducts,
  );
}

export function hasOrderSubscriptionInCart(
  productsInCart: OrderLineItem[],
  countryAvailableProducts: CoreProduct[],
): boolean {
  return hasProductOfProductTypeInCart(
    'license',
    productsInCart.filter((v) => v.code !== SUMUP_ONE_SKU),
    countryAvailableProducts,
  );
}

export const getProductsTrackingInfo = (
  productsContent: Product[],
  lineItems: Pick<
    LineItem,
    'id' | 'total_amount_float' | 'quantity' | 'sku_code' | 'bundle_code'
  >[],
): ProductTrackingInfo[] =>
  lineItems.reduce<ProductTrackingInfo[]>((acc, lineItem) => {
    const product = findProductByCode(
      productsContent,
      (lineItem.sku_code || lineItem.bundle_code) ?? null,
    );

    if (!product) {
      return acc;
    }

    return [
      ...acc,
      {
        code: product.code,
        trackingId: product?.trackingId,
        price: lineItem.total_amount_float.toString(),
        quantity: lineItem.quantity,
      },
    ];
  }, []);

export const getLicenseProductType = (
  products: Product[],
): ProductType | undefined => {
  const licenseProduct = products.find(
    (product) => product.productType?.identifier === 'license',
  );

  return licenseProduct?.productType;
};
