import type { Order } from '@commercelayer/sdk';

import type { AttributionData } from 'checkout/types/checkout';
import type { PaymentPlan } from 'shared/services/PaymentPlanService';
import type { Maybe } from 'types/util';

import {
  getOrderPaymentPlan,
  patchSubscriptionsTaxId,
  type PatchSubscriptionsTaxIdResponse,
  postChargePaymentOnFile,
  type PostChargeResponse,
  postUnplaceOrder,
  postValidateOrder,
  putPlaceOrder,
  type ValidationResponse,
} from '../services';

export const placeOrder = (
  orderId: Order['id'],
  attributionData: AttributionData,
): Promise<unknown> => putPlaceOrder(orderId, attributionData);

export const unplaceOrder = (orderId: Order['id']): Promise<Order> =>
  postUnplaceOrder(orderId);

export const shouldUnplaceOrder = (
  orderStatus: Order['status'],
  paymentReference: Maybe<string>,
): boolean =>
  orderStatus === 'placing' && paymentReference !== 'tokenized_sepa';

export const shouldResetOrder = (
  orderStatus: Order['status'],
  paymentReference: Maybe<string>,
): boolean =>
  orderStatus === 'placing' && paymentReference === 'tokenized_sepa';

export const validateOrder = (
  orderId: string,
  merchantCode: string,
): Promise<ValidationResponse> => postValidateOrder(orderId, merchantCode);

export { getOrderSubscription } from '../services';

export const updateSubscriptionsTaxId = async (
  orderId: string,
  subscriptionIds: string[],
  taxId: string,
): Promise<PatchSubscriptionsTaxIdResponse> =>
  patchSubscriptionsTaxId(orderId, subscriptionIds, taxId);

export const getPaymentPlan = (orderId: string): Promise<PaymentPlan | null> =>
  getOrderPaymentPlan(orderId);

export const chargePaymentOnFile = (
  merchantCode: string,
  orderId: string,
): Promise<PostChargeResponse> =>
  postChargePaymentOnFile(merchantCode, orderId);
